/**
 * 店铺相关API
 */

import request from '@/utils/request'

/**
 * 用户协议
 * @param params
 * @returns {Promise<any>}
 */
export function getShopAgree(params) {
  return request({
    url: 'seller/shops/shopArticle/getDeatail',
    method: 'get',
    params
  })
}

/**
 * 修改/添加协议
 * @param params
 * @returns {Promise<any>}
 */
export function editAgree(params) {
  return request({
    url: 'seller/shops/shopArticle/editInfo',
    method: 'put',
    data: params
  })

}

/**
 * 获取店铺信息
 * @param params
 * @returns {Promise<any>}
 */
export function getShopData() {
  return request({
    url: 'seller/shops',
    method: 'get'
  })
}


/**
 * 获取店铺等级信息
 */
export function getShopLv() {
  return request({
    url: '/seller/shops/shopLv/getShopLv',
    method: 'get'
  })
}

/**
 * 获取商家运费信息
 * @param params
 * @returns {Promise<any>}
 */
export function getSendMsg() {
  return request({
    url: 'seller/shops/goodsPostage/getPostage',
    method: 'get'
  })
}

/**
 * 删除邮费来源表
 * @param params
 * @returns {Promise<any>}
 */
export function delSendMsg(id) {
  return request({
    url: `seller/shops/goodsSourcePostage/${id}`,
    method: 'delete'
  })
}

/**
 * 获取所有商家运费信息
 * @param params
 * @returns {Promise<any>}
 */
export function getAllSendMsg(params) {
  return request({
    url: 'seller/shops/goodsSourcePostage/getPostageList',
    method: 'get',
    params
  })
}

/**
 * 保存商家统一运费
 * @param params
 * @returns {Promise<any>}
 */
export function saveAllSendMoney(params) {
  return request({
    url: 'seller/shops/goodsPostage/edit/postage',
    method: 'put',
    data: params,
    headers: {'Content-Type': 'application/json'}
  })
}

/**
 * 企业-运费设置-所有商品统一设置商品模式
 * @param params
 * @returns {Promise<any>}
 */
export function saveAllPostage(params) {
  return request({
    url: 'seller/shops/goodsPostage/edit/postage',
    method: 'put',
    headers: {'Content-Type': 'application/json'},
    data: params
  })
}

/**
 * 保存商家按来源运费
 * @param params
 * @returns {Promise<any>}
 */
export function saveSendMoney(params) {
  return request({
    url: 'seller/shops/goodsSourcePostage',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
    loading: false
  })
}

/**
 * 保存店铺设置
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function saveShopSettings(params) {
  return request({
    url: 'seller/shops',
    method: 'put',
    loading: false,
    data: params
  })
}

/**
 * 修改店铺库存预警数
 * @param params
 * @returns {Promise<any>}
 */
export function saveStockWarningNum(params) {
  return request({
    url: 'seller/shops/warning-counts',
    method: 'put',
    loading: false,
    data: params
  })
}

/**
 * 提升店铺等级
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function promoteShopGrade(ids, params) {
  return request({
    url: `http://www.andste.cc/mock/5aa72c080d9d060b4b99b45b/seller/settings/shop/grade/${ids}`,
    method: 'post',
    data: params
  })
}

/**
 * 修改店铺logo
 * @param params
 * @returns {Promise<any>}
 */
export function updateShopLogo(params) {
  return request({
    url: 'seller/shops/logos',
    method: 'put',
    data: params
  })
}

/**
 * 获取店铺状态
 */
export function getShopStatus() {
  return request({
    url: 'seller/shops/status',
    method: 'get',
    loading: false
  })
}

/**
 * 获取店铺列表
 */
export function getShopList(params) {
  return request({
    url: 'seller/shops/getShopList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取店铺地址
 */
export function getAddrDetail() {
  return request({
    url: 'seller/shops/refund/address/getDetail',
    method: 'get',
  })
}

/**
 * 保存店铺地址设置
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function saveShopAddress(params) {
  return request({
    url: `seller/shops/refund/address/${params.id}`,
    method: 'put',
    data: params
  })
}


/**
 * 获取首页剩余天数
 */
export function sellerShops() {
  return request({
    url: `seller/shops`,
    method: 'get',
  })
}

/**
 * 查询销售文案列表
 */
export function getGoodsMktpage(params) {
  return request({
    url: `/seller/goodsmkt/goodsMkt/page`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 删除文案信息
 */
export function deleteGoodsMktpage(id) {
  return request({
    url: `/seller/goodsmkt/goodsMkt/delete/${id}`,
    method: 'delete',
    loading: false
  })
}

/**
 * 文案信息上下架
 */
export function changeStaus(id, params) {
  return request({
    url: `/seller/goodsmkt/goodsMkt/changeStaus/${id}`,
    method: 'put',
    loading: false,
    params
  })
}

/**
 * 添加/修改商家销售文案
 */
export function goodsMktedit(params) {
  return request({
    url: `/seller/goodsmkt/goodsMkt/edit`,
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 查询销售文案详情
 */
export function getgoodsMktDet(id) {
  return request({
    url: `/seller/goodsmkt/goodsMkt/${id}`,
    method: 'get',
    loading: false,
  })
}

/**
 * 点击添加的时候调用接口判断是否可以继续操作 1.是可以添加  0.是不可以添加
 */
export function checkShopLv() {
  return request({
    url: `/seller/shops/shopLv/checkShopLv`,
    method: 'get',
    loading: false,
  })
}

/**
 * 获取该企业是否有显示供应商名称的功能
 */
export function getIsShowSupplierName() {
  return request({
    url: 'seller/shops/shopExtAuth/checkAuth',
    method: 'get'
  })
}
/**
 * 问题反馈查询
 */
export function problemFeedbackList (params) {
  return request({
    url: 'seller/shops/problemFeedbackList',
    method: 'get',
    params
  })
}
