/**
 * 导出选择器
 */
import CategoryPicker from './CategoryPicker';
import GoodsPicker from './GoodsPicker';
import ComboPicker from './ComboPicker';
import GoodsPickerFx from './GoodsPickerFx';
import OwnGoodsPickerFx from './OwnGoodsPickerFx';
import ShopPicker from './ShopPicker';
import ImagePicker from './ImagePicker';
import PriceRange from './PriceRange';
import RegionPicker from './RegionPicker';
import TableLayout from './TableLayout';
import TableSearch from './TableSearch';
import TextPicker from './TextPicker';
import ColorPicker from './ColorPicker';
import YearMonthPicker from './YearMonthPicker';
import MemberPicker from './MemberPicker';
import GaijiaPicker from './GaiJiaPicker';
import StorePicker from './StorePicker';
import Tree from './Tree';

const components = {
  CategoryPicker,
  GoodsPicker,
  ComboPicker,
  GoodsPickerFx,
  OwnGoodsPickerFx,
  ShopPicker,
  PriceRange,
  ImagePicker,
  RegionPicker,
  TableLayout,
  TableSearch,
  TextPicker,
  ColorPicker,
  YearMonthPicker,
  MemberPicker,
  GaijiaPicker,
  StorePicker,
  Tree
};

export {
  CategoryPicker,
  GoodsPicker,
  ComboPicker,
  GoodsPickerFx,
  OwnGoodsPickerFx,
  ShopPicker,
  PriceRange,
  ImagePicker,
  RegionPicker,
  TableLayout,
  TableSearch,
  TextPicker,
  ColorPicker,
  YearMonthPicker,
  GaijiaPicker,
  StorePicker,
  Tree
};

export default {
  ...components,
  install(Vue) {
    Object.keys(components).forEach(key => {
      if (typeof components[key] !== 'function') {
        Vue.component(components[key].name, components[key])
      }
    });
  }
}
