/**
 * Created by Andste on 2018/5/28.
 */
import {$xDialog} from '@/components/x-dialog/dialog.proxy';

// import request from '@/utils/request'
// import {domain} from '@/../ui-domain'
// import {RegExp} from '@/../ui-utils'

export default {
  props: {
    // 显示dialog
    showm: {
      type: Boolean,
      default: false
    },
    btnShow: {
      type: Boolean,
      default: true
    },
    // 显示上下架
    shangxiajia: {
      type: Boolean,
      default: true
    },
    shop_type: {
      type: Number,
      default: 0
    },
    batch: {
      type: Boolean,
      default: false,
    },
    idsList: {
      type: Array,
      default: () => {
        return []
      }
    },
    allId: {
      type: Number,
      deafult: 0
    },
    is_qiye_flag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogRef: $xDialog.create({
        title: '商品改价',
        width: '868px',
        displayFooterExtras: true,
        disableCancel: true,
        disableConfirm: true,
        wrapperClass: 'goods-reset-price',
        afterDismiss: () => {
          this.$emit('close', true);
        }
      }),
      // idsList: [],
      // allId: 0,
      radio: '1',
      radio1: '1',
      options: [{
        label: '会员价',
        value: 1
      }, {
        label: '市场价',
        value: 2
      }],
      optionss: [{
        label: '分销价',
        value: 1
      }, {
        label: '市场价',
        value: 2
      }],
      options1: [{
        label: '上调',
        value: 'UP'
      }, {
        label: '下降',
        value: 'DOWN'
      }],
      dialogVisible: this.showm,
      enterprise_price: {
        price_type: 1,
        ratio: 0,
        symbol: 'UP'
      },
      shop_price: {
        price_type: 1,
        ratio: 0,
        symbol: 'UP'
      },
      shop_goods_status: '1',
      cop: '1'
    }
  },
  watch: {
    showm(newVal) {
      if (newVal) {
        this.dialogRef.display();
      } else {
        this.dialogRef.dismiss();
      }
    },
  },
  methods: {
    handleInputChange() {
      if (this.enterprise_price.ratio < 0) {
        this.enterprise_price.ratio = 0

        // /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
      } else if (this.enterprise_price.symbol === 'DOWN' && this.enterprise_price.ratio >= 100) {
        this.enterprise_price.ratio = 100
      }
    },
    handleInputChange1() {
      if (this.shop_price.ratio < 0) {
        this.shop_price.ratio = 0
      } else if (this.shop_price.symbol === 'DOWN' && this.shop_price.ratio >= 100) {
        this.shop_price.ratio = 100
      }
    },
    close() {
      this.$emit('close')
    },
    resSelect() {
      this.$emit('res')
    },
    /** 确认 */
    handleConfirm() {
      if (this.enterprise_price.symbol === 'DOWN' && this.enterprise_price.ratio >= 100) {
        this.enterprise_price.ratio = 100
      }
      if (this.shop_price.symbol === 'DOWN' && this.shop_price.ratio >= 100) {
        this.shop_price.ratio = 100
      }
      const temp = {
        cop: this.cop,
        shop_goods_status: this.shop_goods_status,
        enterprise_price: JSON.parse(JSON.stringify(this.enterprise_price)),
        shop_price: JSON.parse(JSON.stringify(this.shop_price)),
      };

      if (this.radio === '2') {
        temp.enterprise_price.symbol = 'EQUAL'
      }
      if (this.radio1 === '2') {
        temp.shop_price.symbol = 'EQUAL'
      }

      this.$emit('confirm1', temp);

      this.enterprise_price.symbol = 'UP';
      this.shop_price.symbol = 'UP';
    },
  }
}
