<template>
  <x-dialog :proxy="dialogRef">
    <el-form label-width="120px" size="small">
      <el-form-item label="挑选范围" v-if="batch">
        <el-radio-group class="mt-2" v-model="cop">
          <el-radio label="1">已勾选的商品{{ '(共' + idsList.length + '件商品)' }}</el-radio>
          <el-radio label="2">所有商品{{ '(共' + allId + '件商品)' }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        class="reset"
        label="分销价"
        error="作为您与站点商城结算的价格，该站点每产生一笔订单，订单中商品的站点价将从站点商城余额中扣除"
      >
        <div class="row no-gutters align-items-center">
          <div class="col-auto">
            <el-radio v-model="radio" label="1">按照：</el-radio>
          </div>
          <div class="col-auto">
            <el-select
              v-model="enterprise_price.price_type"
              clearable
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>

            <el-select
              v-model="enterprise_price.symbol"
              clearable
              placeholder="请选择"
              style="width: 100px; margin: 0 8px"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>

            <el-input
              placeholder="请输入内容"
              @change="handleInputChange"
              v-model="enterprise_price.ratio"
              clearable
              style="width: 100px"
            >
            </el-input>
          </div>
          <div class="col-auto ml-2">%</div>
        </div>
        <div class="row no-gutters align-items-center mt-3 mb-2">
          <div class="col-auto">
            <el-radio v-model="radio" label="2">等于：</el-radio>
          </div>
          <div class="col-auto">
            <el-select
              v-model="enterprise_price.price_type"
              clearable
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
      </el-form-item>

      <el-form-item
        label="销售价"
        error="作为商城对C端用户的结算价，该站点每产生一笔订单，订单中商品的站点价将从站点商城余额中扣除"
        class="pt-3 reset"
      >
        <div class="row no-gutters align-items-center">
          <div class="col-auto">
            <el-radio v-model="radio1" label="1">按照：</el-radio>
          </div>
          <div class="col-auto">
            <el-select
              v-model="shop_price.price_type"
              clearable
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option
                v-for="item in optionss"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>

            <el-select
              v-model="shop_price.symbol"
              clearable
              placeholder="请选择"
              style="width: 100px; margin: 0 8px"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>

            <el-input
              placeholder="请输入内容"
              @change="handleInputChange1"
              v-model="shop_price.ratio"
              clearable
              style="width: 100px"
            >
            </el-input>
          </div>
          <div class="col-auto ml-2">%</div>
        </div>

        <div class="row no-gutters align-items-center mt-3 mb-2">
          <div class="col-auto">
            <el-radio v-model="radio1" label="2">等于：</el-radio>
          </div>
          <div class="col-auto">
            <el-select
              v-model="shop_price.price_type"
              clearable
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option
                v-for="item in optionss"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
      </el-form-item>

      <el-form-item
        label="上下架"
        error="选择不上架，商品依然将挑选至该站点商城，但是上架状态为未上架，需再次上架后，用户才可见。默认直接上架"
        class="pt-3 reset"
        v-if="shangxiajia"
      >
        <el-radio-group class="mt-2" v-model="shop_goods_status">
          <el-radio label="1">直接上架</el-radio>
          <el-radio label="0">暂不上架</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <div slot="footer-extras">
      <el-button @click="resSelect" v-if="btnShow" size="small">重新选择商城</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </div>
  </x-dialog>
</template>

<script>
import mixin from './mixin';
import XDialog from '@/components/x-dialog/x-dialog';

export default {
  name: 'EnGaijiaPicker',
  components: {XDialog},
  mixins: [mixin],
  data () {
    return {
      // btnShow: false
    }
  },
  mounted() {
    if (this.$route.name === 'marketGood') {
      this.btnShow = true
    } else if (this.$route.name === 'marketGooda') {
      this.btnShow = false
    }
  }
};
</script>

<style lang="scss">
.goods-reset-price {

  .el-input-group__prepend {
    padding: 0;

    .el-select {
      width: 100px;
      margin: 0;

      * {
        border: none;
      }
    }

    .el-input__inner {
      transform: translateY(0px);
    }
  }

  .el-form-item.is-error .el-input__inner {
    border-color: #DCDFE6 !important;
  }

  .el-input__inner {
    transform: translateY(1px);
  }

  .el-form-item.reset {
    .el-form-item__content {
      padding-top: 28px;
      position: relative;

      .el-form-item__error {
        color: #666;
        position: absolute;
        top: 8px;
      }
    }
  }
}
</style>
