import Vue from 'vue';
import * as echarts from 'echarts';
import axios from 'axios';
// @ts-ignore
import VueWorker from 'vue-worker';
import Storage from 'vue-ls';

import App from './App.vue';
import router from './router/index';
import { getStorage } from './storage';
import CKEditor from 'ckeditor4-vue';
import UIComponents from '@/../ui-components';
import EnComponents from './components';
import store from './store';
import * as filters from './filters';
import directives from './directives/loadmore';
import mixin from './utils/mixin';
import i18n from './lang';
import './permission';
import './plugins/index';

import ElementUI from 'element-ui'
Vue.use(CKEditor);
(() => {
  const options = {
    namespace: 'sshl__', // key prefix
    name: 'ls', // name variable Vue.[ls] or this.[$ls],
    storage: 'local', // storage name session, local, memory
  };
  Vue.use(Storage, options);

  Vue.use(directives);
  UIComponents.install(Vue);
  Vue.use(EnComponents);
  Vue.use(VueWorker);
  Vue.use(ElementUI)
})();

(() => {
  Vue.config.productionTip = false;
  Vue.prototype.$echarts = echarts;
  Vue.prototype.$http = axios;
  Vue.prototype.$getStorage = getStorage;
  if (location.host.includes('two') || location.host.includes('172.20') || location.host.includes('192.168')) {
    Vue.prototype.$url = '.fcshoptwo.sshlqf.com/';
  } else {
    Vue.prototype.$url = (location.host.includes('test') ? '.m' : '') + '.fcshop.bzoe.com.cn/';
  }

  // register global utility filters.
  Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
  });

  Vue.mixin(mixin);
})();

(() => {
  // 屏蔽输出日志
  // console.log(process.env.ENV_CONFIG);
  // if (process.env.ENV_CONFIG !== 'dev') {
  //   console.log = () => {
  //     // ?
  //   };
  // }
})();

(() => {
  window['app'] = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  });

  router.onReady(() => {
    window['app'].$mount('#app');
  });
})();
